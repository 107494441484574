/**
* Generated automatically at built-time (2025-04-01T09:13:33.876Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-planung-outdoorkueche",templateKey: "sites/108-ff32e712-1865-4318-8b42-2531d75d6a23"};