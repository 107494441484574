/**
* Generated automatically at built-time (2025-04-01T09:13:34.037Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-black-friday",templateKey: "sites/108-b858c7d7-28cf-4f17-aeef-dc8ac77b2545"};